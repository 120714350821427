import React from 'react';
import Main from './components/Main';
import './styles/styles.scss';

function App() {
  return (
    <Main />
  );
}

export default App;