import React from 'react';

function Main() {
  return (
    <section className="section">
      <div className="spacer"></div>
      <div className="container">
        <h1 className="title">
          En construction
        </h1>
        <h3 className="description">
        Ce site web est en construction.
        Contactez nous sur contact@compganieazul.com ou sur ig
        @<a href="https://www.instagram.com/compagnieazul/" target="_blank" rel="noreferrer">compagnieazul</a>
        </h3>
      </div>
    </section>
  );
}

export default Main;